<template>
  <a-modal :visible="visible" :width="580" @confirm="handleOk" @cancel="handleCancel">
    <template #title>
      <winTitle :title="modalTitle" sub-title="" />
    </template>
    <template slot="footer">
      <a-button @click="handleCancel">取消</a-button>
      <a-button @click="handleOk" type="primary" :loading="loading">提交</a-button>
    </template>
    <a-form-model ref="basicForm" :model="info" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol"
                  :colon="false">
      <a-form-model-item label="收件人" prop="concat">
        <a-input placeholder="请输入" v-model="info.concat" :maxLength="20" />
      </a-form-model-item>
      <a-form-model-item label="手机号" prop="concatPhone">
        <a-input placeholder="请输入" v-model="info.concatPhone" />
      </a-form-model-item>
      <a-form-model-item label="所在地区" prop="region">
        <a-cascader :options="region" placeholder="请选择" v-model="info.region" @change="onRegionChange" />
      </a-form-model-item>
      <a-form-model-item label="详情地址" prop="address">
        <a-textarea placeholder="请输入" v-model="info.address" autoSize max-length="80"/>
      </a-form-model-item>
      <a-form-model-item label="默认地址" prop="default">
        <a-radio-group name="radioGroup" :default-value="1" v-model="info.defaultFlag">
          <a-radio :value="1">
            是
          </a-radio>
          <a-radio :value="0">
            否
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>

  </a-modal>
</template>

<script>
import DrawerView from '@/components/plugins/drawerView.vue'
import { mapGetters } from 'vuex'
import { postAction, getAction } from '@/api/manage'
import Captcha from '@/components/tools/captcha.vue'
import WinTitle from '@/components/winTitle/index.vue'
import region from '@/util/region.json'

export default {
  name: 'address',
  components: {
    DrawerView,
    Captcha,
    WinTitle
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      let reg = new RegExp(/^(?:(?:\+|00)86)?1[3-9]\d{9}$/)
      if (!value) {
        callback(new Error('请输入手机号'))
      } else if (!reg.test(value)) {
        callback(new Error('请输入有效的手机号'))
      } else {
        callback()
      }
    }
    return {
      modalTitle: '新增地址',
      region,
      sendPhone: null,
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      visible: false,
      loading: false,
      info: {},
      sendCodeSecA: 0,
      sendCodeSecB: 0,
      timerA: null,
      timerB: null,
      type: 1,
      rules: {
        concatPhone: { required: true, validator: validatePhone },
        address: { required: true, message: '请输入地址' },
        region: { type: 'array', required: true, message: '请选择', trigger: 'change' },
        concat: { required: true, message: '请输入收件人' }
      }
    }
  },
  destroyed() {
    this.timerA && clearInterval(this.timerA)
    this.timerB && clearInterval(this.timerB)
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleEdit() {
      const { userId, phone } = this.setUserInfo()
      this.info = {
        userId,
        oldPhone: phone
      }
      getAction('/user/pc/user/queryUserPhone', { userId }).then(res => {
        const { success, data } = res
        if (success && data) {
          this.$set(this.info, 'oldPhone', data)
        }
      })
      this.visible = true
    },
    handleCancel() {
      this.visible = false
      this.loading = false
      this.info = {}
    },
    handleOpen(item) {
      this.info = {...item}
      console.log(item?.id)
      this.modalTitle = '添加地址'
      if (item?.id) {
        this.modalTitle = '修改地址'
        this.info.region = [item.provinceCode, item.cityCode, item.areaCode]
      } else if (item?.concat) {
        this.modalTitle = '复制地址'
        this.info.region = [item.provinceCode, item.cityCode, item.areaCode]
      }

      this.visible = true
      this.loading = false
    },
    handleOk() {
      const that = this
      this.$refs.basicForm.validate(valid => {
        if (valid) {
          this.loading = true
          if (this.info.id) {
            postAction('/address/updateById', this.info).then(res => {
              const { message, success } = res
              that.loading = false
              if (success) {
                that.$message.success('修改成功')
                this.$emit('ok')
                that.handleCancel()
              } else {
                that.$message.warning(message)
              }
            })
          } else {
            postAction('/address/insert', this.info).then(res => {
              const { message, success } = res
              that.loading = false
              if (success) {
                that.$message.success('添加成功')
                this.$emit('ok')
                that.handleCancel()
              } else {
                that.$message.warning(message)
              }
            })
          }

        }
      })
    },
    onRegionChange(value, selectedOptions) {
      this.info.provinceName = selectedOptions[0].label
      this.info.cityName = selectedOptions[1].label
      this.info.areaName = selectedOptions[2].label

      this.info.provinceCode = selectedOptions[0].value
      this.info.cityCode = selectedOptions[1].value
      this.info.areaCode = selectedOptions[2].value
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .ant-form-item {
  margin-bottom: 14px;
}

::v-deep .ant-form-item-required::before {
  //display: none;
}

::v-deep .ant-btn-link {
  padding: 0;
}

::v-deep .ant-input {
  margin-left: 10px;
}

::v-deep .ant-input-suffix {
  right: 10px;
}

::v-deep .ant-input {
  height: 38px;
}

::v-deep textarea {
  min-height: 38px;
}

::v-deep .ant-cascader-picker-label {
  padding-left: 20px;
}


</style>
