/*
 * @Description: 列表加载混合器
 * @Author: zhang zhen
 * @Date: 2023-03-15 14:35:15
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-04-07 22:23:11
 * @FilePath: /page-sass/src/mixins/listViewLoader.js
 */
import { getAction, postAction, postQueryAction } from "@/api/manage" 
export default {
    data() {
        return {
           queryParams: {},
           pageNation: {
                pageNum: 1,
                pageSize: 20,
                total: 0
           },
           sorter: {},
           dataSource: [{},{},{}],
           loading: false,
           requestType: 'get'
        }
    },
    created () {
        //!this.disableMixinCreated && this.handleLoadData(1);
    },
    methods: {
        handleChangePage({currentPage, pageSize }) {
            this.pageNation.pageNum = currentPage
            this.pageNation.pageSize = pageSize
            this.handleLoadData()
        },
        handleLoadData(flag) {
            if (flag) {
                this.pageNation = {
                    pageNum: 1,
                    pageSize: 20,
                    total: 0
               }
            }
            if (!this.url && !this.url.list) return this.$message.warning('请设置请求地址')
            let pageParams = {...this.pageNation}
            delete pageParams.total
            this.loading = true
            this.dataSource = []
            const { pageSize, pageNum } = pageParams
            let api = this.requestType == 'get' ? getAction : this.requestType == 'post' ? postAction : postQueryAction;
            api(this.url.list, {
                ...this.queryParams,
                pagesSize: pageSize,
                pageNum,
                ...this.sorter
            }).then(res => {
                const { success, data } = res;
                this.loading = false
                if (success) {
                    const { total, list } = data
                    this.dataSource = list
                    this.pageNation['total'] = total
                }
            }).catch(e => {
                this.loading = false
            })
        }  
    },
}